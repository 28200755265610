exports.components = {
  "component---src-components-markdown-markdown-js": () => import("./../../../src/components/markdown/markdown.js" /* webpackChunkName: "component---src-components-markdown-markdown-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-chinese-gushiwen-extra-js": () => import("./../../../src/pages/articles/chinese/gushiwen-extra.js" /* webpackChunkName: "component---src-pages-articles-chinese-gushiwen-extra-js" */),
  "component---src-pages-articles-chinese-gushiwen-fenghuaxueyue-js": () => import("./../../../src/pages/articles/chinese/gushiwen-fenghuaxueyue.js" /* webpackChunkName: "component---src-pages-articles-chinese-gushiwen-fenghuaxueyue-js" */),
  "component---src-pages-articles-chinese-gushiwen-song-js": () => import("./../../../src/pages/articles/chinese/gushiwen-song.js" /* webpackChunkName: "component---src-pages-articles-chinese-gushiwen-song-js" */),
  "component---src-pages-articles-chinese-gushiwen-tang-js": () => import("./../../../src/pages/articles/chinese/gushiwen-tang.js" /* webpackChunkName: "component---src-pages-articles-chinese-gushiwen-tang-js" */),
  "component---src-pages-articles-index-js": () => import("./../../../src/pages/articles/index.js" /* webpackChunkName: "component---src-pages-articles-index-js" */),
  "component---src-pages-articles-japanese-japanese-fifty-js": () => import("./../../../src/pages/articles/japanese/japanese-fifty.js" /* webpackChunkName: "component---src-pages-articles-japanese-japanese-fifty-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-resume-index-js": () => import("./../../../src/pages/resume/index.js" /* webpackChunkName: "component---src-pages-resume-index-js" */)
}

